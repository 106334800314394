import React from 'react'
import Layout from '../../layouts'
import SEO from '../../components/SEO'
import PostList from '../../components/PostList'
import Container from '../../components/Container'
import Pagination from '../../components/Pagination'
import ArrowRight from '../../assets/icons/arrow_right.svg'
import './listing.scss'

class Listing extends React.Component {
  render () {
    const { siteMeta, title, description, location, posts, basePath, currentPage, numPages } = this.props

    return (
      <Layout
        location={location}
        siteMeta={siteMeta}
      >
        <SEO
          title={title}
          description={description}
          article={false}
          pathname={location.pathname}
        />
        <Container>
          {
            basePath !== '/' ? 
            <div className="listing-header">
              <h1 className="listing-title">
              <span>
                {title}
              </span>
              <span>
                <ArrowRight />
              </span>    
              </h1>
            </div>: null
          }
          <PostList
            posts={posts}
            siteMeta={siteMeta}
          />
          { numPages > 1? 
            <Pagination currentPage={currentPage} numPages={numPages} basePath={basePath} />: null          
          }
        </Container>
      </Layout>
    )
  }
}

export default Listing
