import React from 'react'
import './PostCard.scss'
import {getImage, GatsbyImage } from "gatsby-plugin-image"
import startCase from 'lodash/startCase'

class PostCard extends React.Component {
  render () {
    const image = getImage(this.props.post.frontmatter.image)

    return (
      <article
        className={
          'post-card ' + (this.props.type === 'featured' ? 'featured' : '')
        }
        itemType="https://schema.org/Article"
      >
        <a href={this.props.post.fields.slug} className="card-link">
          {this.props.post.frontmatter.image ? 
          <figure
            className="card-media"
            itemProp="image"
            itemType="https://schema.org/ImageObject"
          >
            <GatsbyImage
              className="card-image"
              alt={this.props.post.frontmatter.title}
              image={image}
          />
          </figure>: null          
          }
          <div className="card-text">
            <h3 className="card-title" style={{ WebkitBoxOrient: 'vertical' }}>
              {this.props.post.frontmatter.title}
            </h3>
            <span className="card-category card-detail">
              {startCase(this.props.post.frontmatter.categories[0])}
            </span>
            <span className="card-date card-detail" itemProp="datePublished">
                { this.props.post.frontmatter.date }
            </span>
            <span className="card-reading-time card-detail">
              {this.props.post.timeToRead} mins read
            </span>
          </div>
        </a>
      </article>
    )
  }
}

export default PostCard
