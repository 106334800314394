import React from 'react'
import { graphql } from 'gatsby'
import Listing from './listing'

class BlogList extends React.Component {
  render() {
    const siteMeta = this.props.data.site.siteMetadata
    const posts = this.props.data.allMdx.edges
    const { basePath, currentPage, numPages } = this.props.pageContext


    return (
      <Listing
        title={`${siteMeta.title}${currentPage > 1 ? ` Page ${currentPage}`: ''}`}
        description={`${siteMeta.description}${currentPage > 1 ? ` Page ${currentPage}`: ''}`}
        siteMeta={this.props.data.site.siteMetadata}
        location={this.props.location}
        basePath={basePath}
        posts={posts}
        currentPage={currentPage}
        numPages={numPages} />
    )
  }
}

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        name
        title
        description
        author
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { 
          type: { eq: "post" } 
          status: { ne: "draft" }
          categories: {nin: ["interview"]}
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            categories
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 650)
              }
            }
          }
        }
      }
    }
  }
`